.reg-form-page {
  padding: 5px;
}

#reg {
  margin: 10vh auto;
	max-width: 779px;
  width: 100%;
	background: #FFFFFF;
	border-radius: 40px;
	padding: 48px 165px 60px 165px;
}

#reg span.head {
	font-family: FuturaNew;
	font-style: normal;
	font-weight: bold;
	font-size: 48px;
	line-height: 61px;
	color: #314F8A;
	text-align: center;
	display: block;
	padding-bottom: 8px;
}

div#reg div.group {
	width: 70%;
	margin: 0 auto;
}

div.group {
	margin-top: 24px;
}

div.group span.title {
	font-family: FuturaNew;
	font-weight: normal;
	font-size: 20px;
	line-height: 117.1%;
	letter-spacing: 0.035em;
	color: #838389;
	padding-left: 20px;
	padding-bottom: 8px;
	display: block;
}

input,
select {
	outline: none;
  width: 100%;
}

#reg input,
#reg select {
	box-sizing: border-box;
	height: 46px;
	background: #FFFFFF;
	border: 2px solid rgba(83, 72, 117, 0.6);
	border-radius: 38px;
}

#reg .group.err input,
#reg select.err {
	background: #ffb0b0a2;
	border: 2px solid rgba(219, 21, 21, 0.6);
}

#reg .error-message {
  color: rgba(219, 21, 21, 0.6);
}

div#reg {
	text-align: center;
	padding: 48px;
}

div#reg img {
	margin: 48px 0;
}

#reg input {
	padding-left: 20px;
	padding-right: 20px;
}

#sendForm {
	font-family: FuturaNew;
	padding: 8px 87px;
	background: #FFDC40 !important;
	font-style: normal;
	font-size: 24px;
	line-height: 30px;
	color: #323557;
	box-shadow: -2px 4px 0px #DB994B, 2px 4px 0px #DB994B;
	border-radius: 42px;
	margin-top: 32px;
	cursor: pointer;
	border: none !important;
}

#sendForm:hover,
#startOlimp:hover {
	background: #F0CE34 !important;
}

@media all and (max-width: 790px) {
  #reg {
    margin: 5px 0;
    padding: 48px 50px 60px 50px;
  }
}

